
// TopBar
import '../styles/navigation.css'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function TopBar() {
  return (
    <div className="TopBar">
      <Container>
        <Row>
          <Col lg={8} md={8} className="top-info text-center text-md-start">
            <ul className="list-unstyled">
              <li>
                <i className="fas fa-map-marker-alt"></i>
                <p className="info-text">Leicester, NC USA</p>
              </li>
            </ul>
          </Col>
          <Col lg={4} md={4} className="top-social text-center text-md-end">
            <ul className="list-unstyled">
              <li>
                <a title="Facebook" href="https://facebook.com/steamymakes">
                  <span className="social-icon"><i className="fab fa-facebook-f"></i></span>
                </a>
                <a title="Twitter" href="https://twitter.com/steamymakes">
                  <span className="social-icon"><i className="fab fa-twitter"></i></span>
                </a>
                <a title="Instagram" href="https://instagram.com/steamymakes">
                  <span className="social-icon"><i className="fab fa-instagram"></i></span>
                </a>
                <a title="Linkdin" href="https://github.com/themefisher.com">
                  <span className="social-icon"><i className="fab fa-github"></i></span>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
