import Button from 'react-bootstrap/Button';
import logo from '../assets/logo.svg';
import '../styles/Home.css';

function Home() {
  return (
    <div className="Home">
      <header className="Home-header">
        <img src={logo} className="Home-logo" alt="logo" />
        <p>
          Edit <code>src/pages/Home.js</code> and save to reload.
        </p>
        <Button variant="primary">Learn React</Button>
      </header>
    </div>
  );
}

export default Home;
