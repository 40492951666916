import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Routes, Route } from "react-router-dom";
import './styles/index.scss';
import App from './pages/App';
import Home from "./pages/Home";
import About from "./pages/About";
import reportWebVitals from './reportWebVitals';


/*
  <React.StrictMode>
    <App />
  </React.StrictMode>

*/

ReactDOM.render(
  <HashRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="home" element={<Home />} />
      <Route path="about" element={<About />} />
    </Routes>
  </HashRouter>
  , document.getElementById('root')
);
//

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
