import { Link } from "react-router-dom";
//import logo from '../assets/logo.svg';
import '../styles/App.css';
import TopBar from '../components/TopBar';
import Navigation from '../components/Navigation';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function App() {
  return (
    <div className="App">
      <TopBar />
      <Container fluid className="honey-tile row justify-content-center">
        <h1 className="row justify-content-center">Sluder Branch Honey</h1>
        <Navigation />
      </Container>
      <Link to="/home">Home</Link> |{" "}
      <Link to="/about">About</Link>
      <Container fluid className="comb-tile fixed-bottom">
        <Row>
          <Col>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </Col>
        </Row>

      </Container>
    </div>
  );
}

export default App;
